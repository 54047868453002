/* eslint-disable no-unused-expressions */
import React from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import styled from '@emotion/styled';
import useAuthContext from 'hooks/useAuthContext';
import { Button, Input } from '@xchange/uikit';
import notify from 'notify';

const LoginPage = () => {
  const { login } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const submitForm = async values => {
    try {
      await login(values);
      navigate('/');
    } catch (err) {
      notify(err.message);
    }
  };

  return (
    <StyledLoginPage>
      <h1 className="title">Welcome back!</h1>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          {...register('email', {
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
          label="Email"
          placeholder="Enter Email"
          error={errors.email?.message}
        />
        <br />
        <Input
          {...register('password', { required: 'Required' })}
          label="Password"
          placeholder="Enter Password"
          type="password"
        />
        <br />
        <Link className="forgot-password" to="/forgot-password">
          Forgot password?
        </Link>
        <br />
        <Button className="button-submit">Login</Button>
      </form>
    </StyledLoginPage>
  );
};

export default LoginPage;

const StyledLoginPage = styled.div`
  /* mobile first */
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }

  form {
    .input,
    .button {
      width: 100%;
    }

    .input {
      margin-bottom: 20px;
    }

    .forgot-password {
      display: inline-block;
      font-size: 14px;
      line-height: 30px;
      color: ${props => props.theme.colors.darkCementGray};
      text-decoration: none;
      margin: 4px 0 55px 0;
    }

    .button-submit {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 30px;
    }

    .info {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 46px;
    }

    form {
      .input {
        width: 300px;
      }

      .forgot-password {
        margin-bottom: 108px;
      }

      .button-submit {
        height: 40px;
        width: 300px;
      }
    }
  }
`;
